<template>
    <div class="home">
        <div class="content">
            
            <div class="pizza">
                <img src="../assets/pizza.png"/>
            </div>
            <div class="name">Bake A Pizza</div>
            <div class="text">
                Welcome to the first Pizza Factory built for all Layer 2 explorers.
                Gather ingredients and bake pizza with a simple tap.
            </div>
            <a href="https://t.me/bakeapizza_bot" target="_blank">
                <div class="start">Start Baking</div>
            </a>
        </div>

        <div class="magic" ref="magic_ref" :style="{'left': left, 'top': top}" :data-top="top">
            <div class="pizza_magic">
                <div class="rot">
                    <pizza_vue :size="'100%'" class="rot"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onUnmounted, ref } from "vue"

import pizza_vue from "@/icons/pizza.vue"



const left = ref('50px')
const top = ref('50px')

const magic_ref = ref()

// const is_mobile = ref(false)

// const mouse_move = (e) => {

//     if (is_mobile.value) { return }

//     let half_width = magic_ref.value.scrollWidth / 2
//     left.value = e.pageX - half_width + 'px'
//     top.value = e.pageY - half_width + 'px'

// }

const animate_magic = () => {

    let magic_width = magic_ref.value.scrollWidth / 2

    var h = window.innerHeight - magic_width;
    var w = window.innerWidth - magic_width;
    
    top.value = Math.floor(Math.random() * h) + 'px'
    left.value = Math.floor(Math.random() * w) + 'px'

}

var animate_interval = null

const init = () => {
    // if (window.innerWidth < 700) {

        animate_interval = setInterval(() => {
            animate_magic()
        }, 800);

    // }
}
init()

onUnmounted(() => {
    clearInterval(animate_interval)
})

</script>

<style scoped>

.home {
    position: relative;
    height: 100vh;

    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
}

.content {
    max-width: 500px;
    width: 100%;
    position: relative;
    z-index: 3;
        background-color: rgba(46, 52, 60, 0.3);
        backdrop-filter: blur(1px);
    padding: 20px;
    border-radius: 20px;
}
.pizza {
    fill: #FFD700;
    text-align: center;
}
.pizza img {
    width: 100px;
}
.name {
    text-align: center;
    font-size: 25px;
    font-weight: 800;
    margin-top: 20px;
}
.text {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    margin: 20px 0px;
    line-height: 22px;
}
.start {
    background-color: #FFD700;
    color: black;
    font-weight: 600;
    padding: 17px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}



.magic {
    /* z-index: 5; */
    position: absolute;
    /* top: calc(50% - 10rem); */
    /* left: calc(50% - 10rem); */
    width: 20rem;
    height: 20rem;
    background: url("../assets/pizza-bg.jpg") 50% 50% no-repeat fixed;
    background-size: cover;
    border-radius: 50%;

    transition: .7s;
}
.pizza_magic {
    background-color: #2e343c;
    mix-blend-mode: multiply;
    
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    overflow: hidden;
    

    display: flex;
    align-items: center;
    justify-content: center;
}

.rot {
    animation: spin 5s infinite linear;
    width: 22rem;
    height: 22rem;
    
    fill: rgb(255, 205, 235);
    transition: .2s;
}
@keyframes spin {
    0% {
        fill: rgb(255, 205, 235);
    }
    30% {
        fill: rgb(205, 255, 213);
    }
    70% {
        fill: rgb(245, 255, 205);
    }
    100% {
        fill: rgb(255, 205, 235);
    }
}

@media screen and (max-width: 700px) {
    .magic {
        transition: .7s;
    }
    .content {
        
        background-color: rgba(46, 52, 60, 0.8);
        backdrop-filter: blur(1px);
    }
}
</style>