<template>
    <div class="app">
        <router-view/>

    </div>
</template>


<script setup>
document.title = 'Bake A Pizza'
</script>

<style>
@import 'https://fonts.googleapis.com/css2?family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap';

#app {
  font-family: "Ubuntu Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #2e343c;
}

body {
    padding: 0px;
    margin: 0px;

    background-color: #2e343c;
    user-select: none;
}
a {
    color: inherit;
    fill: inherit;
    text-decoration: none;
}
input {
    font-family: "Ubuntu Sans", sans-serif;
    font-size: 16px;
}


</style>
